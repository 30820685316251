"use client";
import { ArrowFillIcon } from "@/components/icon";
import styles from "./footer-form.module.scss";
import { FC, useState } from "react";
import Link from "next/link";
import isValidEmail from "@/utils/functions/is-valid-email";
import { mindboxSubscribe } from "@/utils/api/mindbox";
import { callMessage } from "@/utils/functions/callMessage";
import { googleFooterSubscription } from "@/utils/api/google-analytic";

export const FooterForm: FC = () => {
  const [email, setEmail] = useState("");
  const validate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      return callMessage("Ошибка!", "Не заполнено поле с Email.", false);
    } else if (!isValidEmail(email)) {
      return callMessage(
        "Ошибка!",
        "Неверный Email. Пожалуйста, проверьте поле с Email.",
        false
      );
    }
    callMessage("Успешно!", "Вы подписались на новости.", true);
    const data = {
      customer: {
        email: email.trim(),
      },
    };
    const expires = new Date();
    expires.setTime(expires.getTime() + (
      30 * 24 * 60 * 60 * 1000));
    mindboxSubscribe(data)
      .then((res) => {
        const expiresString = `expires=${expires.toUTCString()}`;
        document.cookie = `MBSubScribe=true; ${expiresString}; path=/`;
      });;
    ym(42989679, "reachGoal", "subscribe");
    googleFooterSubscription();
    setEmail("");
  };
  return (
    <form className={styles.form}>
      <div className={styles.formInputWrap}>
        <input
          className={styles.formInput}
          id="footer-form-input"
          type="text"
          placeholder="Введите e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name="email"
          autoComplete="off"
        />
        <button onClick={validate} className={styles.formButton}>
          <ArrowFillIcon />
        </button>
      </div>
      <p className={styles.formText}>
        Нажимая «Отправить», вы принимаете{" "}
        <Link prefetch={false} href="/policy/" className={styles.formLink}>
          условия политики персональных данных
        </Link>
      </p>
    </form>
  );
};
